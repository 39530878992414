<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import {dateUtil} from "@/helpers/date-util";
import {jsonUtil} from "@/helpers/json-util";

/**
 * Allegro Auctions Component
 */
export default {

  components: {
    Layout,
    PageHeader
  },

  computed: {
    jsonUtil() {
      return jsonUtil
    },

    paginationHelper() {
      return paginationHelper
    },

    getSelectedShopId() {
      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  data() {
    return {
      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100, 200],
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: false,

        elements: null
      },

      warehouses: null,

      price: {
        shippingRates: [],
        current: {
          name: ""
        }
      },

      showModal: false,

      submitted: false,
      allegroAccountId: ""
    }
  },

  validations: {
    price: {
      current: {
        name: {required}
      },
    },
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('allegro-auctions.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "sku", label: 'SKU'},
        {key: "variationId", label: 'ID Wariacji'},
        {key: "allegroAuctionId", slot: true, label: 'ID Oferty'},
        // {key: "shopId", label: 'Sklep'},
        {key: "title", label: 'Tytuł'},
        {
          key: "allegroCategoryId", label: 'Kategoria', formatter: value => {
            if (!value) {
              return '-'
            }

            return value
          }
        },
        {key: "warehouseId", slot: true, label: 'Hurtownia'},
        {key: "price", label: 'Cena'},
        {key: "quantity", label: 'Ilość'},
        {key: "status", slot: true, label: 'Status'},
        {key: "ean", slot: true, label: 'EAN'},
        {key: "createdAt", label: 'Data utworzenia', formatter: dateUtil.asDateTimeNormal},
        {
          key: "updatedAt", label: 'Data aktualizacji', formatter: value => {
            if (!value) {
              return '-'
            }

            return dateUtil.asDateTimeNormal(value)
          }
        },
        {
          key: "validationMessage", label: 'Status walidacji', formatter: value => {
            if (!value) {
              return '-'
            }

            return value
          }
        },
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadAuctions() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/allegro/auction/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            allegroAccountId: this.allegroAccountId,
            page: page,
            size: this.table.perPage
          }
        });

        this.table.items = data.auctions;
        this.table.totalRows = data.count;
        this.table.rows = data.count;

        await this.loadWarehouses()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items;
    },

    async loadWarehouses() {
      if (this.table.items.length === 0) {
        return;
      }

      const ids = [];
      for (let allegroAuction of this.table.items) {
        ids.push(allegroAuction.warehouseId);
      }

      const json = {
        ids: ids
      }

      const {data} = await axios.post(`/warehouse/fetch`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.warehouses = new Map(data.map((obj) => [obj.id, obj]));
    },

    getWarehouse(id) {
      if (!this.warehouses) {
        return {}
      }

      const warehouse = this.warehouses.get(id)
      if (!warehouse) {
        return {}
      }

      return warehouse
    }

  },

  beforeMount() {
    this.allegroAccountId = this.$route.params.allegroAccountId;
  }
}

</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('allegro-auctions.title')"/>
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">

                <div class="py-4">
                  <div v-if="allegroAccountId">
                    <ecat-table
                        ref="table"
                        :fields="getFields()"
                        :items="loadAuctions"
                        :pagination-top="true"
                        :table="table"
                    >
                      <template v-slot:ean="{ item }">
                        <div :set="ean = jsonUtil.asArray(item.ean)">
                          <template v-if="ean.length > 0">
                            <span>{{ ean[0] }}</span>
                            <span v-if="ean.length > 1" class="badge badge-soft-success font-size-11">+{{
                                ean.length - 1
                              }} {{ $t('message.others') }}</span>
                          </template>
                          <template v-else>
                            <span>-</span>
                          </template>
                        </div>
                      </template>

                      <template v-slot:allegroAuctionId="{ item }">
                        <a
                            v-if="item.allegroAuctionId"
                            :href="`https://allegro.pl/oferta/${item.allegroAuctionId}`"
                            target="_blank"
                        >{{ item.allegroAuctionId }}</a>
                        <a v-else>
                          -
                        </a>
                      </template>

                      <template v-slot:warehouseId="{ item }">
                        <span>{{ getWarehouse(item.warehouseId).name }}</span>
                      </template>

                      <template v-slot:status="{ item }">
                        <span :class="$t(`allegro-auctions.status-color.${item.status}`)" class="badge">{{
                            $t(`allegro-auctions.status.${item.status}`)
                          }}</span>
                      </template>

                      <template v-slot:action>
                        <!--                          <a @click="console.log('todo ' + item.id)" class="clickable-element text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>-->
                      </template>
                    </ecat-table>
                  </div>
                  <div v-else class="text-center">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>